import type { StateOperator } from '@ngxs/store';

import type { SortingOptions } from '@clover/core/helpers/paging';

import type { ProductPreview } from './products-state.model';
import type { ProductsSortingProperty } from './products.service';

export class LoadProducts {
  static readonly type = '[Products] Load Products';

  constructor(
    public payload: {
      query?: string;
      worklistId?: number | undefined;
      sortingOptions?: SortingOptions<ProductsSortingProperty>;
    },
  ) {}
}

export class LoadNextProducts {
  static readonly type = '[Products] Load Next Products';
}

export class DeleteProduct {
  static readonly type = '[Products] Delete product';

  constructor(public productId: number) {}
}

export class DeleteProductsFromWorklist {
  static readonly type = '[Products] Delete products from worklist';

  constructor(
    public worklistId: number,
    public productIds: number[],
  ) {}
}

export class PatchProduct {
  static readonly type = '[Products] Patch product';

  constructor(
    public productId: number,
    public patch: StateOperator<ProductPreview>,
  ) {}
}

export class RemoveProduct {
  static readonly type = '[Products] Remove product';

  constructor(public productId: number) {}
}
