import { inject } from '@angular/core';
import { CanActivateFn, createUrlTreeFromSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import * as R from 'ramda';
import { lastValueFrom, take } from 'rxjs';

import { ProductsSelectors } from './state/products.selectors';
import { WorklistsService } from './state/worklists.service';

export type ProductWorklistRouteParam = string | undefined;

export const productsListGuard: CanActivateFn = async (route) => {
  const {
    queryParams: { query: queryParam, worklist: worklistParam },
  } = route;

  const [queryValid, query] = validateQueryParam(queryParam);
  const [worklistValid, worklist] = await validateWorklistParam(worklistParam, inject(WorklistsService));

  if (queryValid && worklistValid) return true;

  const segmentParams = {
    query,
    worklist,
  };

  // Remove undefined parameters
  const definedParams = R.pickBy((value) => !!value, segmentParams);

  return createUrlTreeFromSnapshot(route, [], definedParams);
};

function validateQueryParam(param: string | undefined): [boolean, string] {
  return [true, param || inject(Store).selectSnapshot(ProductsSelectors.productsQuery)];
}

async function validateWorklistParam(
  param: ProductWorklistRouteParam,
  worklistsService: WorklistsService,
): Promise<[boolean, ProductWorklistRouteParam]> {
  if (!param) return [true, param];

  const worklistExists = await lastValueFrom(worklistsService.checkWorklistExistance(Number(param)).pipe(take(1)));
  if (!worklistExists) return [false, undefined];

  return [true, param];
}

export function productWorklistToRouteParam(worklistId: number | null): ProductWorklistRouteParam {
  return worklistId ? String(worklistId) : undefined;
}

export function routeParamToProductWorklist(param: ProductWorklistRouteParam): number | null {
  return param ? Number(param) : null;
}
