import { inject, Injectable } from '@angular/core';
import { map, type Observable } from 'rxjs';

import { HttpService } from '@clover/core/services/http.service';
import type ProductWorklist from '@clover/products/models/product-worklist';

interface ProductWorklistResponse {
  id: number;
  name: string;
}

interface DeleteProductsFromWorklistResponse {
  isWorklistDeleted: boolean;
  deletedProductIds: number[];
}

export interface DeleteProductsFromWorklistResult {
  worklistDeleted: boolean;
  deletedProductIds: number[];
}

function mapProductWorklist(w: ProductWorklistResponse): ProductWorklist {
  return {
    id: w.id,
    name: w.name,
  };
}

function mapDeleteProductsFromWorklistResponse(
  response: DeleteProductsFromWorklistResponse,
): DeleteProductsFromWorklistResult {
  return {
    worklistDeleted: response.isWorklistDeleted,
    deletedProductIds: response.deletedProductIds,
  };
}

@Injectable({
  providedIn: 'root',
})
export class WorklistsService {
  private readonly http = inject(HttpService);

  getWorklists(): Observable<ProductWorklist[]> {
    return this.http
      .getV2<ProductWorklistResponse[]>('/api/companies/my/productWorklists')
      .pipe(map((worklists) => worklists.map(mapProductWorklist)));
  }

  checkWorklistExistance(worklistId: number): Observable<boolean> {
    return this.getWorklists().pipe(map((worklists) => worklists.some((w) => w.id === worklistId)));
  }

  deleteProductsFromWorklist(worklistId: number, productIds: number[]): Observable<DeleteProductsFromWorklistResult> {
    return this.http
      .patchV2<DeleteProductsFromWorklistResponse>(`/api/companies/my/productWorklists/${worklistId}`, {
        deletedProductIds: productIds,
      })
      .pipe(map(mapDeleteProductsFromWorklistResponse));
  }
}
