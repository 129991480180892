<header class="products-header">
  <div class="products-header__details">
    <h2 class="products-header__details-title">Products</h2>

    <p class="products-header__details-description">
      A catalog of your company’s products. Product information is made available to the network and your connections
      can see extended product information.
    </p>
  </div>

  <div class="products-header__actions">
    <app-button
      [type]="ButtonType.Secondary"
      [size]="ButtonSize.Small"
      (click)="import.emit()"
    >
      <i
        slot="start-icon"
        class="icon-import-product"
      >
      </i>

      Import
    </app-button>

    <app-button
      [type]="ButtonType.Primary"
      [size]="ButtonSize.Small"
      [routerLink]="['/', 'products', 'create']"
    >
      New product
    </app-button>
  </div>
</header>
