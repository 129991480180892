@if (product(); as product) {
  <td
    ccTd
    ccVerticalAlignment="center"
    ccHorizontalAlignment="start"
  >
    @if (showCheckbox()) {
      <app-checkbox
        [checked]="checked()"
        (click)="$event.stopPropagation()"
        (change)="checkboxChange.emit($event)"
      />
    }

    <div class="product-details-cell">
      <img
        [src]="product.imageUrl || 'assets/svg/common/new-company-placeholder.svg'"
        [alt]="product.name"
        [width]="88"
        [height]="88"
        class="product-details-cell__image"
      />

      <div class="product-details-cell__content">
        <div class="product-details-cell__content-name">
          {{ product.name }}
        </div>

        <div class="product-details-cell__content-metadata">
          <div class="product-details-cell__content-metadata-title">GTIN / UPC</div>
          <div class="product-details-cell__content-metadata-value">{{ product.metadata.gtin || 'N/A' }}</div>
        </div>
      </div>
    </div>
  </td>

  <td
    ccTd
    ccVerticalAlignment="center"
    ccHorizontalAlignment="start"
  >
    <cc-product-access-chip [accessType]="product.access" />
  </td>

  <td
    ccTd
    ccVerticalAlignment="center"
    ccHorizontalAlignment="start"
  >
    <div [class.cc-unavailable]="!product.vendor.companyName && !product.vendor.internalCompanyName">
      {{ product.vendor.companyName || product.vendor.internalCompanyName || 'N/A' }}
    </div>
  </td>

  <td
    ccTd
    ccVerticalAlignment="center"
    ccHorizontalAlignment="start"
  >
    <div [class.cc-unavailable]="!product.metadata.internalItemNumber">
      {{ product.metadata.internalItemNumber || 'N/A' }}
    </div>
  </td>

  <td
    ccTd
    ccVerticalAlignment="center"
    ccHorizontalAlignment="start"
  >
    <app-button
      (click)="$event.stopPropagation()"
      ccTooltip="More actions"
      [tooltipAlignment]="TooltipAlignment.End"
      [active]="dropdownTrigger.isOpen()"
      [type]="ButtonType.Ghost"
      [size]="ButtonSize.Small"
      #dropdownTrigger="ccDropdownTrigger"
      [ccDropdownTrigger]="dropdown"
      [ccDropdownPositions]="{
        offsetY: 6,
        positions: ['bottom-end', 'top-end'],
      }"
    >
      <i
        class="icon-more"
        slot="ghost-icon"
      ></i>
    </app-button>

    <ng-template #dropdown>
      <cc-dropdown width="160px">
        <cc-dropdown-action
          [routerLink]="['/', 'products', product.id, 'edit']"
          (click)="dropdownTrigger.close()"
        >
          <ng-container slot="title"> Edit </ng-container>
        </cc-dropdown-action>

        <cc-dropdown-action (click)="assignTask.emit(); dropdownTrigger.close()">
          <ng-container slot="title"> Assign task </ng-container>
        </cc-dropdown-action>

        <cc-dropdown-divider />

        <cc-dropdown-action (click)="confirmDelete(); dropdownTrigger.close()">
          <ng-container slot="title"> Delete </ng-container>
        </cc-dropdown-action>
      </cc-dropdown>
    </ng-template>
  </td>
}
