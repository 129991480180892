<div class="products-list">
  <ng-scrollbar
    (scrolled)="loadNextProducts()"
    [infiniteScrollDistance]="4"
    [infiniteScrollThrottle]="0"
    [infiniteScrollUpDistance]="4"
    [scrollWindow]="false"
    cdkScrollable
    infiniteScroll
    class="products-list__list"
  >
    <div class="products-list__list-wrapper">
      <cc-products-header
        (import)="handleProductsImport()"
        class="products-list__list-wrapper-header"
      />
      <cc-products-table class="products-list__list-wrapper-list" />
    </div>
  </ng-scrollbar>
</div>
