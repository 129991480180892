import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';

import { ProductAccessType } from '@clover/products-v2/state/products-state.model';

const backgroundColors: Partial<Record<ProductAccessType, string>> = {
  [ProductAccessType.Public]: 'var(--comp-status-chip-color-in-progress-bg, #E3F4FC)',
  [ProductAccessType.Private]: 'var(--comp-status-chip-color-closed-bg, #DCDFE3)',
};

const foregroundColors: Partial<Record<ProductAccessType, string>> = {
  [ProductAccessType.Public]: 'var(--comp-status-chip-color-in-progress-fg, #04689A)',
  [ProductAccessType.Private]: 'var(--comp-status-chip-color-closed-fg, #5E6A75)',
};

const defaultBackgroundColor = 'var(--comp-status-chip-color-closed-bg, #DCDFE3)';
const defaultForegroundColor = 'var(--comp-status-chip-color-closed-fg, #5E6A75)';

@Component({
  selector: 'cc-product-access-chip',
  standalone: true,
  templateUrl: './product-access-chip.component.html',
  styleUrls: ['./product-access-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductAccessChipComponent {
  accessType = input.required<ProductAccessType>();

  protected readonly backgroundColor = computed(() => backgroundColors[this.accessType()] ?? defaultBackgroundColor);
  protected readonly foregroundColor = computed(() => foregroundColors[this.accessType()] ?? defaultForegroundColor);

  protected readonly ProductAccessType = ProductAccessType;
}
