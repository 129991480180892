import { inject, Injectable } from '@angular/core';
import { Action, State, type StateContext } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import * as R from 'ramda';
import { catchError, forkJoin, map, of, switchMap, tap, type Observable } from 'rxjs';

import { CdkPortalService } from '@clover/core/services/cdk-portal.service';
import { UserService } from '@clover/core/services/user.service';
import { ToastType } from '@design/overlays/toast/toast';

import { defaultProductsState, ProductDeleteError, type ProductsStateModel } from './products-state.model';
import {
  DeleteProduct,
  DeleteProductsFromWorklist,
  LoadNextProducts,
  LoadProducts,
  PatchProduct,
  RemoveProduct,
} from './products.actions';
import { ProductsService } from './products.service';
import { WorklistsService } from './worklists.service';

@State<ProductsStateModel>({
  name: 'products',
  defaults: defaultProductsState,
})
@Injectable()
export class ProductsState {
  private readonly productsService = inject(ProductsService);
  private readonly worklistsService = inject(WorklistsService);
  private readonly userService = inject(UserService);
  private readonly portal = inject(CdkPortalService);

  get userCompanyId(): number {
    return this.userService.userCompany.id;
  }

  @Action(LoadProducts, { cancelUncompleted: true })
  loadProducts(ctx: StateContext<ProductsStateModel>, { payload }: LoadProducts): Observable<void> {
    const state = ctx.getState();
    const query = R.isNil(payload.query) ? state.query : payload.query;
    const worklistId = payload.worklistId === undefined ? state.worklistId : payload.worklistId;
    const sorting = payload.sortingOptions || state.sorting;

    ctx.patchState({ loadingStatus: 'loading' });

    return forkJoin([
      this.worklistsService.getWorklists(),
      this.productsService.searchProducts(this.userCompanyId, query, worklistId, {
        offset: 0,
        orderBy: sorting.orderBy,
        order: sorting.order,
        limit: 50,
      }),
    ]).pipe(
      tap(([worklists, products]) => {
        ctx.patchState({ products, worklists, query, worklistId, sorting, loadingStatus: 'loaded' });
      }),
      catchError(() => {
        ctx.patchState({ loadingStatus: 'error' });
        return of(undefined);
      }),
      map(() => undefined),
    );
  }

  @Action(LoadNextProducts, { cancelUncompleted: true })
  loadNextProducts(ctx: StateContext<ProductsStateModel>): Observable<void> {
    const state = ctx.getState();
    const offset = state.products.count;
    const query = state.query;
    const worklistId = state.worklistId;
    const sortingOptions = state.sorting;

    ctx.patchState({ loadingStatus: 'loading-next' });

    return this.productsService
      .searchProducts(this.userCompanyId, query, worklistId, {
        offset,
        orderBy: sortingOptions.orderBy,
        order: sortingOptions.order,
        limit: 50,
      })
      .pipe(
        tap((nextProducts) => {
          ctx.setState(
            patch<ProductsStateModel>({
              products: patch({
                data: append(nextProducts.data),
                count: state.products.count + nextProducts.count,
                paging: nextProducts.paging,
              }),
              loadingStatus: 'loaded',
            }),
          );
        }),
        catchError(() => {
          ctx.patchState({ loadingStatus: 'error' });
          return of(undefined);
        }),
        map(() => undefined),
      );
  }

  @Action(DeleteProduct)
  deleteProduct(ctx: StateContext<ProductsStateModel>, { productId }: DeleteProduct): Observable<void> {
    return this.productsService.deleteProduct(this.userCompanyId, productId).pipe(
      tap(() => {
        ctx.dispatch(new RemoveProduct(productId));
        this.portal.presentToast('Product deleted', ToastType.Success);
      }),
      catchError((errorResponse) => {
        const errorCodes: string[] =
          errorResponse?.error?.errors?.map((error: { errorCode: string }) => error?.errorCode) || [];

        if (errorCodes.includes(ProductDeleteError.ProductHasTasks)) {
          this.portal.presentToast('This product has tasks and cannot be deleted', ToastType.Error);
          return;
        } else if (errorCodes.includes(ProductDeleteError.ProductHasIncompleteTasks)) {
          this.portal.presentToast('This product has incomplete tasks and cannot be deleted', ToastType.Error);
          return;
        }

        this.portal.presentToast('Failed to delete product', ToastType.Error);
        return of(errorResponse);
      }),
    );
  }

  @Action(DeleteProductsFromWorklist)
  deleteProductsFromWorklist(
    ctx: StateContext<ProductsStateModel>,
    { worklistId, productIds }: DeleteProductsFromWorklist,
  ): Observable<void> {
    return this.worklistsService.deleteProductsFromWorklist(worklistId, productIds).pipe(
      switchMap(({ deletedProductIds, worklistDeleted }) => {
        this.portal.presentToast('Products removed from worklist', ToastType.Success);

        if (worklistDeleted) return ctx.dispatch(new LoadProducts({ worklistId: null }));

        ctx.setState(
          patch<ProductsStateModel>({
            products: patch({
              data: removeItem((product) => deletedProductIds.includes(product.id)),
              count: (count) => count - deletedProductIds.length,
              total: (total) => total - deletedProductIds.length,
            }),
          }),
        );

        return of(undefined);
      }),
      catchError(() => {
        this.portal.presentToast('Failed to remove products from worklist', ToastType.Error);
        return of(undefined);
      }),
      map(() => undefined),
    );
  }

  @Action(PatchProduct)
  patchProduct(ctx: StateContext<ProductsStateModel>, { productId, patch: patchedValue }: PatchProduct): void {
    ctx.setState(
      patch<ProductsStateModel>({
        products: patch({
          data: updateItem((product) => product.id === productId, patchedValue),
        }),
      }),
    );
  }

  @Action(RemoveProduct)
  removeProduct(ctx: StateContext<ProductsStateModel>, { productId }: RemoveProduct): void {
    ctx.setState(
      patch<ProductsStateModel>({
        products: patch({
          data: removeItem((product) => product.id === productId),
          count: (count) => count - 1,
          total: (total) => total - 1,
        }),
      }),
    );
  }
}
