import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Store } from '@ngxs/store';
import { catchError, map, of } from 'rxjs';

import { routeParamToProductWorklist } from './products-list.guard';
import { LoadProducts } from './state/products.actions';

export const productsListResolver: ResolveFn<boolean> = (route) => {
  const { query = '', worklist: worklistIdRoute } = route.queryParams;

  const worklistId = routeParamToProductWorklist(worklistIdRoute);

  const store = inject(Store);
  return store.dispatch(new LoadProducts({ query, worklistId })).pipe(
    map(() => true),
    catchError(() => of(false)),
  );
};
