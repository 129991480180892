<div
  [style.background]="backgroundColor()"
  [style.color]="foregroundColor()"
  class="product-status-chip"
>
  @switch (accessType()) {
    @case (ProductAccessType.Public) {
      PUBLIC
    }
    @case (ProductAccessType.Private) {
      PRIVATE
    }
  }
</div>
