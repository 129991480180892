<cc-table
  [ccSearchFormControl]="queryFormControl"
  [ccSearchable]="true"
  [ccStickyHeader]="true"
  ccPadding="medium"
  ccSearchPlaceholder="Search products..."
  [ccEmptyRowTemplate]="emptyRowTemplate"
  [ccShowBulkActions]="checkedProductIds$().length > 0"
>
  <ng-container slot="cc-table-title">
    @if (productsCount$(); as productsCount) {
      {{ productsCount }} {{ productsCount === 1 ? 'product' : 'products' }}
    }
  </ng-container>

  <ng-container slot="cc-table-bulk-actions-count"> {{ checkedProductIds$().length }} selected</ng-container>
  <ng-container slot="cc-table-bulk-actions">
    <app-button
      [disabled]="true"
      ccTooltip="Assign task"
      [size]="ButtonSize.Big"
      [type]="ButtonType.Ghost"
    >
      <i
        class="icon-tasks"
        slot="ghost-icon"
      ></i>
    </app-button>

    <app-button
      [disabled]="!activeWorklist$()"
      (click)="removeProductsFromWorklist()"
      ccTooltip="Remove from worklist"
      [size]="ButtonSize.Big"
      [type]="ButtonType.Ghost"
    >
      <i
        class="icon-delete"
        slot="ghost-icon"
      ></i>
    </app-button>
  </ng-container>

  <ng-container slot="сс-table-custom-actions">
    <cc-select [formControl]="worklistSelectFormControl">
      <ng-container ccOptions>
        @for (option of worklistSelectItems(); track option.id) {
          <cc-select-item
            [id]="option.id"
            [value]="option.title"
            [payload]="option.payload"
          />
        }
      </ng-container>
    </cc-select>
  </ng-container>

  <ng-container slot="cc-table">
    <thead>
      <tr ccTr>
        <th
          ccTh
          (ccSortChange)="changeSort('name', $event)"
          [ccSortDirection]="nameSortingOrder$()"
          [ccSortable]="true"
        >
          <app-checkbox
            (click)="$event.stopPropagation()"
            (change)="toggleAllProducts($event)"
            [checked]="areAllProductsChecked$()"
            [disabled]="!hasLoadedProducts$()"
            [intermediate]="isAllProductsCheckboxIndeterminate$()"
          />

          Product
        </th>

        <th
          ccTh
          (ccSortChange)="changeSort('accessType', $event)"
          [ccSortDirection]="accessSortingOrder$()"
          [ccSortable]="true"
          ccWidth="160px"
        >
          Access
        </th>

        <th
          ccTh
          (ccSortChange)="changeSort('vendor', $event)"
          [ccSortDirection]="vendorSortingOrder$()"
          [ccSortable]="true"
          ccWidth="184px"
        >
          Vendor
        </th>

        <th
          ccTh
          (ccSortChange)="changeSort('internalItemNumber', $event)"
          [ccSortDirection]="itemNumberSortingOrder$()"
          [ccSortable]="true"
          ccWidth="160px"
        >
          Item #
        </th>

        <th
          ccTh
          ccWidth="48px"
        ></th>
      </tr>
    </thead>

    <tbody>
      @for (product of products$(); track product.id) {
        <tr
          ccTr
          [ccClickable]="true"
          [routerLink]="['/', 'products', product.id]"
        >
          <cc-product-table-row
            [product]="product"
            [showCheckbox]="true"
            [checked]="checkedProductIds$().includes(product.id)"
            (checkboxChange)="toggleProduct($event, product.id)"
            (assignTask)="assignTask(product.id)"
            (delete)="deleteProduct(product.id)"
          />
        </tr>
      }
    </tbody>
  </ng-container>
</cc-table>

<ng-template #emptyRowTemplate> There are no products to display. </ng-template>
